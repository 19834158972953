<template>
  <v-app>
    <v-card>
      <v-card-title>Crear producto o bundle</v-card-title>
      <v-card-text class="d-flex justify-space-around">
        <v-radio-group label="Seleccione el tipo:" v-model="mode" row>
          <v-radio label="Producto" value="product"></v-radio>
          <v-radio label="Bundle" value="bundle"></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="mode == 'product'">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="product.name" :rules="nameRules" label="Nombre" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="product.sku" :rules="nameRules" label="SKU" required></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="product.ext_sku" label="SKU externo" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="product.ean" :rules="nameRules" label="EAN" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="product.price"
              :rules="priceRules"
              label="Precio"
              suffix="$"
              required
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="category"
              :items="categoriesList"
              :rules="categoryRules"
              label="Categoría"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="product.high"
              :rules="priceRules"
              label="Altura"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="product.width"
              :rules="priceRules"
              label="Ancho"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="product.depth"
              :rules="priceRules"
              label="Profundidad"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="product.weight"
              :rules="priceRules"
              label="Peso"
              required
              suffix="kg"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-btn color="primary" :disabled="loading" @click="sendFormProduct">Enviar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="mode == 'bundle'">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="bundle.name" :rules="nameRules" label="Nombre" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="bundle.sku" :rules="nameRules" label="SKU" required></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="bundle.ext_sku" label="SKU externo" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="bundle.ean" :rules="nameRules" label="EAN" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="bundle.price"
              :rules="priceRules"
              label="Precio"
              suffix="$"
              required
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="bundle.category"
              :items="categoriesList"
              :rules="categoryRules"
              label="Categoría"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="selectProducts"
              :items="productsList"
              label="Productos del bundle"
              multiple
              chips
              :return-object="true"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.high"
              :rules="priceRules"
              label="Altura"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.width"
              :rules="priceRules"
              label="Ancho"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.depth"
              :rules="priceRules"
              label="Profundidad"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.weight"
              :rules="priceRules"
              label="Peso"
              required
              suffix="kg"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-btn color="primary" :disabled="loading" @click="sendFormBundle">Enviar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          v-show="selectProducts.length && mode == 'bundle'"
          :headers="headers"
          :items="selectProducts"
          no-results-text="No hay resultados disponibles"
          no-data-text="No hay datos disponibles"
          loading-text="Cargando datos..."
          class="elevation-1 datatable"
        >
          <template v-slot:item.quantity="{ item }">
            <td class="text-center">
              <v-text-field v-model="item.quantity" type="number" min="1"></v-text-field>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  data() {
    return {
      mode: "product",
      category: undefined,
      product: {
        name: "",
        ean: "",
        sku: "",
        price: undefined,
        high: 0.0,
        width: 0.0,
        depth: 0.0,
        weight: 0.0,
        ext_sku: undefined,
      },
      bundle: {
        name: "",
        ean: "",
        sku: "",
        price: undefined,
        products: [],
        high: 0.0,
        width: 0.0,
        depth: 0.0,
        weight: 0.0,
      },
      nameRules: [
        (v) => !!v || "El Campo es requerido",
        //v => v.length <= 10 || 'asdf',
      ],
      priceRules: [
        (v) => !!v || "El Campo es requerido",
        (v) => v > 0 || "Debes ingresar un valor mayor a 0",
      ],
      categoryRules: [
        (v) => !!v || "Debes elegir una opción"
      ],
      loading: false,
      productsList: [],
      categoriesList: [],
      selectProducts: [],
      headers: [
        { text: "Producto", value: "text" },
        { text: "Cantidad", value: "quantity" },
      ],
      dateMsg:
        new Date().toISOString().substr(0, 10) +
        " " +
        new Date().toISOString().substr(11, 5),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard productos", route: "/productos" },
      { title: "Crear producto o bundle", route: "/productos/crear" },
    ]);
    this.fetchProducts();
    this.fetchCategories();
  },
  methods: {
    fetchProducts() {
      this.productsList = [];
      var vm = this;
      this.axios({
        url: "order/products_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.productsList = response.data.map((e) => ({ ...e, quantity: 1 }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCategories(){
      this.categoriesList = [];
      var vm = this;
      this.axios({
        url: "inventory/categories_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.categoriesList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleMode() {
      if (this.mode == "bundle") {
        console.log("Se debe ejecutar fetchProducts");
        this.fetchProducts();
      }
    },
    sendFormProduct() {
      let createProduct = this.product;

      this.loading = true;
      var vm = this;
      this.axios({
        url: "order/products/product",
        method: "POST",
        data: {...vm.product, category_id: vm.category},
      })
        .then((response) => {
          vm.loading = false;
          vm.$bvToast.toast(
            `El producto: ${createProduct.name}, ha sido creado, el ${vm.dateMsg} exitosamente!`,
            {
              title: `Información`,
              variant: "success",
              solid: true,
              toaster: "b-toaster-bottom-center",
            }
          );
          //Set ALert to LocalStorage to show on the redirect page
          localStorage.setItem(
            "crearProducto",
            JSON.stringify({
              name: createProduct.name,
            })
          );
          //rediect to dashboard Products
          vm.$router.push("/productos");
        })
        .catch((error) => {
          vm.loading = false;
          console.log(error);
        });
    },
    sendFormBundle() {
      let createBundle = this.bundle;

      this.loading = true;
      var vm = this;
      this.axios({
        url: "order/products/bundle",
        method: "POST",
        data: { ...this.bundle, products: this.selectProducts },
      })
        .then((response) => {
          vm.loading = false;
          vm.$bvToast.toast(
            `El Bundle: ${createBundle.name}, ha sido creado, el ${vm.dateMsg} exitosamente!`,
            {
              title: `Información`,
              variant: "success",
              solid: true,
              toaster: "b-toaster-bottom-center",
            }
          );

          localStorage.setItem(
            "crearBundle",
            JSON.stringify({
              name: createBundle.name,
            })
          );

          //rediect to dashboard Products
          vm.$router.push("/productos");
        })
        .catch((error) => {
          vm.loading = false;
          console.log(error);
        });
    },
  },
};
</script>